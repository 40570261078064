var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-app-bar',{staticClass:"layout-appbar__appbar",attrs:{"id":"home-app-bar","app":"","elevation":"1","elevate-on-scroll":"","height":"80","dark":_vm.isDark()}},[_c('base-img',{staticClass:"mr-3 hidden-xs-only",attrs:{"src":"logo.png","contain":"","max-width":"150","width":"100%"}}),_c('v-toolbar-title',{staticClass:"hidden-lg-only"},[_vm._v(_vm._s(_vm.$t('appBar.title')))]),_c('v-spacer'),_c('v-toolbar-items',{staticClass:"hidden-sm-and-down"},[_c('v-btn',{attrs:{"text":"","to":"/"}},[_vm._v(_vm._s(_vm.$t('appBar.home')))]),_c('v-menu',{attrs:{"open-on-hover":"","rounded":"0","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('appBar.solutions.title'))+" ")])]}}])},[_c('v-list',{attrs:{"dark":_vm.isDark()}},_vm._l((_vm.$t('appBar.solutions.items')),function(item,index){return _c('v-list-item',{key:index,attrs:{"to":item.href}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-uppercase"},[_vm._v(_vm._s(item.name))])],1),_c('v-list-item-action',[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-chevron-right")])],1)],1)}),1)],1),_c('v-btn',{attrs:{"text":"","to":"/partners"}},[_vm._v(_vm._s(_vm.$t('appBar.partners')))]),_c('v-btn',{attrs:{"text":"","to":"/contact-us"}},[_vm._v(_vm._s(_vm.$t('appBar.contact')))]),_c('v-btn',{attrs:{"text":""},on:{"click":() => {
          if(_vm.$i18n.locale == 'en'){
            _vm.$i18n.locale = 'es';
          }else{
            _vm.$i18n.locale = 'en';
          }
        }}},[_vm._v(" "+_vm._s(_vm.$i18n.locale)+" ")])],1),_c('v-app-bar-nav-icon',{staticClass:"hidden-md-and-up",on:{"click":function($event){$event.stopPropagation();return _vm.$store.commit('switchDrawer')}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }