<template>
  <v-dialog v-model="dialog" width="650px">
    <template v-slot:activator="{ on, attrs }">
      <a class="white--text pa-1 pa-md-0" v-bind="attrs" v-on="on"
        >Privacy Policy</a
      >
    </template>

    <v-card>
      <v-card-title class="text-h5">
        Privacy Policy
      </v-card-title>

      <v-card-text>
        <p style="text-align: justify; ">
          De acuerdo con la "Ley Federal de Protección de Datos Personales en
          Posesión de los Particulares”, “ACERHO” declara ser una empresa
          constituida legalmente y es responsable del trato de sus datos
          personales, por la presente le informa que la información
          proporcionada por nuestros clientes a través de diferentes
          formularios, Registro de Usuario en el Sistema, Solicitud de
          Información, Adquisición de Servicios, entre otros formularios que
          podrán integrarse en el futuro; serán tratados de forma estrictamente
          confidencial, por lo que al proporcionar sus datos personales, tales
          como: 1. Nombre/Razón social. 2. Dirección. 3. Registro Federal de
          Contribuyentes. 4. Oficina y teléfonos celulares 5. Correo
          electrónico. 6. Información bancaria. Estos serán utilizados única y
          exclusivamente para los siguientes fines: 1. Campañas Publicitarias.
          2. Campañas de Lealtad. 3. Información y Prestación de Servicios. 4.
          Actualización de la Base de Datos. 5. Cualquier finalidad análoga o
          compatible con las anteriores. Para evitar el acceso no autorizado a
          sus datos personales y con el fin de asegurar que la información sea
          utilizada para los fines establecidos en el presente aviso de
          privacidad, hemos establecido diversos procedimientos con el fin de
          evitar el uso o divulgación no autorizada de sus datos, permitiéndonos
          tratarlos adecuadamente. Asimismo, le informamos que sus datos
          personales podrán ser transmitidos para ser tratados por otras
          personas. Todos sus datos personales son tratados de conformidad con
          la legislación aplicable vigente en el país, por lo que le informamos
          que tiene en todo momento los derechos de acceso, rectificación,
          cancelación u oposición al tratamiento que le damos a sus datos
          personales; derecho que puede hacer valer a través del Área de
          Privacidad responsable de la seguridad de los datos personales en el
          correo electrónico contacto@acerho.com y a través de este sitio web
          www.acerho.com puede actualizar sus datos y especificar el medio por
          el cual desea recibir información. El presente aviso de privacidad
          podrá ser modificado por “ACERHO”, dichas modificaciones serán
          debidamente informadas a través de correo electrónico, teléfono,
          página web o cualquier otro medio de comunicación determinado para
          este propósito.
        </p>
        <h2 style="">Aviso de Privacidad ACERHO</h2>
        <p style="text-align: justify; ">
          ACERHO S.A.P.I. DE C.V. (en lo sucesivo “ACERHO”), con domicilio en
          Insurgentes Sur 577, entre calle Ohio, Dakota y diagonal Viaducto,
          Alcaldía Benito Juárez, Ciudad de México, C.P. 03810, por este
          conducto y con la finalidad de dar cumplimiento a lo establecido por
          la Ley Federal de Protección de Datos Personales en Posesión de los
          Particulares (en adelante la Ley), ACERHO proporciona el presente
          Aviso de Privacidad para informarle la forma en que recabamos,
          transferimos, almacenamos y utilizamos los datos personales, bajo los
          principios de licitud, consentimiento, información, calidad,
          finalidad, lealtad, proporcionalidad y responsabilidad.
        </p>
        <h4 style="text-align: left; ">
          I. Finalidad del Tratamiento de datos.
        </h4>
        <p style="text-align: justify; ">
          ACERHO es responsable de recabar, tratar y proteger los datos
          personales, con la finalidad de a) Verificar la identidad de sus
          clientes y la veracidad de la información que proporcionen como
          propia; b) Formalizar una relación contractual entre el cliente y
          ACERHO con la finalidad de prestar los servicios contratados conforme
          a lo pactado entre las partes; c) Integrar expedientes y bases de
          datos de sus clientes, ya sea directamente por ACERHO o a través de
          un tercero y reportar a las autoridades competentes dicha información
          cuando así lo establezcan las disposiciones legales vigentes; d)
          Ofrecer a sus clientes una ampliación o mejora de los productos o
          servicios contratados; e) Ofrecer beneficios, promociones o descuentos
          a sus clientes, y entre otras finalidades que ACERHO determine dentro
          de sus políticas con motivo de la relación contractual y/o comercial
          que en su caso llegare a tener con sus clientes.
        </p>
        <p style="text-align: justify; ">
          Además de las finalidades generalizadas en el párrafo anterior, es
          importante que el cliente comprenda que ACERHO requiere la
          recopilación de esta información con la finalidad de realizar
          actividades inherentes a los servicios contratados, como lo son:
        </p>
        <div style="margin-left: auto; margin-right: auto; ">
          <ul>
            <li>
              Proveer de hardware y software relacionado con los servicios
              contratados.
            </li>
            <li>
              Poder realizar las transacciones en favor sus clientes.
            </li>
            <li>
              Enviar vouchers, estados de cuenta, desglose de las transacciones,
              pagos y facturas.
            </li>
            <li>
              Brindar soporte y asesoría relacionada con los servicios
              contratados.
            </li>
            <li>
              Proveer de información o materiales mercadotécnicos y
              publicitarios.
            </li>
            <li>
              Creación, integración, actualización y conservación del expediente
              de sus clientes.
            </li>
          </ul>
        </div>
        <br />
        <h4 class="text-left" style="">
          II. Recopilación de datos.
        </h4>
        <p style="text-align: justify; ">
          Los datos personales podrán ser recabados por ACERHO directamente,
          por un tercero o mediante otras fuentes permitidas por la Ley y se
          tratarán con la finalidad exclusiva de realizar y dar seguimiento al
          proceso de contratación de los clientes a ACERHO por cualquier
          producto y/o servicio que este último proporcione.
        </p>
        <p style="text-align: justify; ">
          Los clientes proporcionarán la información al momento de iniciar el
          proceso de contratación mencionado en el párrafo anterior y al
          suscribir el presente Aviso, así como al momento de modificar la
          información entregada a ACERHO. Los datos personales que se
          recopilarán son los siguientes:
        </p>
        <div>
          <ul style="">
            <li>Datos de Identificación.</li>
            <li>Datos de Contacto.</li>
            <li>Datos Informáticos.</li>
            <li>Datos de Geolocalización.</li>
            <li>Datos Laborales.</li>
            <li>Datos Comerciales.</li>
            <li>Datos financieros y/o patrimoniales.</li>
          </ul>
        </div>
        <br />
        <p style="text-align: justify; ">
          De manera automática, también se recolecta información acerca de su
          uso de los sitios y servicios mediante cookies, contadores de
          visitantes, java script, archivos de registro, píxeles y otra
          tecnología, que pueden incluir: su nombre de dominio, tipo de
          buscador, preferencia de idioma del buscador, tipo de dispositivo y
          sistema operativo, visitas a la página y vínculos en los que usted
          hace clic dentro de los sitios, direcciones IP, identificación de
          dispositivo u otro identificador, información de ubicación, indicación
          de fecha y hora y tiempo que utilizó los Servicios, URL referido y su
          actividad dentro de los sitios.
        </p>
        <p style="text-align: justify; ">
          De forma enunciativa más no limitativa, se señala a continuación
          información que recabará ACERHO de los clientes:
        </p>
        <ul style="">
          <li>
            Personas Físicas:
            <ul>
              <li>Nombre y apellidos.</li>
              <li>Edad.</li>
              <li>Género.</li>
              <li>Dirección de correo electrónico.</li>
              <li>Teléfono celular.</li>
              <li>Datos de identificación.</li>
              <li>RFC</li>
            </ul>
          </li>
          <li>Personas Morales:</li>
          <ul>
            <li>Denominación o razón social</li>
            <li>Domicilio</li>
            <li>Correo electrónico</li>
            <li>Teléfono</li>
            <li>RFC</li>
            <li>Nombre del Representante legal</li>
            <li>Giro de comercio</li>
            <li>Volumen de operaciones</li>
          </ul>
        </ul>
        <p style="text-align: justify; ">
          En caso de que el cliente no proporcione la información antes
          mencionada, ACERHO podrá abstenerse de prestarle sus servicios. El
          cliente manifiesta que los datos personales proporcionados a ACERHO
          son veraces y actualizados y se obliga a comunicar a ACERHO cualquier
          modificación a los datos personales proporcionados con la mayor
          brevedad posible a través de los medios establecidos para ello por
          ACERHO.
        </p>
        <p style="text-align: justify; ">
          Los datos que recabe ACERHO directamente del cliente, serán recabados
          a través de los Medios Electrónicos que se pongan a disposición.
          ACERHO podrá realizar las investigaciones, obtener fotografías,
          realizar entrevistas, y en general, realizar las acciones que
          considere necesarias a efecto de comprobar por sí o a través de
          terceros contratados particularmente para ello, la veracidad de los
          datos personales que les fueron proporcionados por el cliente.
        </p>
        <p style="text-align: justify; ">
          El mismo tratamiento a que se refiere el presente Aviso, tendrán los
          datos personales recabados en lo futuro, el cliente podrá ejercer en
          cualquier momento su derecho de oposición, según lo establecido en el
          presente Aviso.
        </p>
        <p style="text-align: justify; ">
          ACERHO no solicita ninguna información que bajo la Ley se entiendan
          como datos sensibles, tales como religión, preferencias sexuales,
          opiniones políticas, entre otros. En ACERHO apoyamos y fomentamos la
          diversidad; prohibimos cualquier acto discriminatorio o la solicitud
          de cualquier información que pudiera implicar un acto discriminatorio.
        </p>
        <h4 style="text-align: left; ">
          III. Tratamiento de Datos.
        </h4>
        <p style="text-align: justify; ">
          El tratamiento de la información recabada por ACERHO tendrá el
          tratamiento necesario para la única finalidad señalada en el apartado
          I del presente Aviso.
        </p>
        <p style="text-align: justify; ">
          Así mismo, de manera adicional y como finalidades secundarias, que si
          bien no son necesarias para la relación jurídica entre ACERHO y el
          cliente, permitirán en su caso, proveer diversos servicios o productos
          adicionales, o mejorar los ya prestados y proporcionados: (i) ofrecer
          beneficios, promociones o descuentos al cliente que tengan por objeto
          incentivar o premiar el uso de los productos o servicios que
          comercializa ACERHO; y (iii) elaborar encuestas de calidad y
          satisfacción, respecto de los productos contratados.
        </p>
        <p style="text-align: justify; ">
          En caso de que el cliente no desee que sus datos personales sean
          tratados para los fines secundarios, deberá manifestar su negativa a
          los datos de contacto de ACERHO, señalados en el presente Aviso.
        </p>
        <h4 style="text-align: left; ">
          IV. Transferencia de datos.
        </h4>
        <p style="text-align: justify; ">
          ACERHO utiliza los datos personales de sus clientes exclusivamente
          para los fines señalados en el presente Aviso y los conserva por el
          período señalado en las disposiciones legales aplicables. Con la
          aceptación del presente Aviso se entiende que el cliente otorga su
          autorización para que ACERHO transfiera sus datos personales a
          terceros, ya sean mexicanos o extranjeros sin que para ello se
          requiera recabar nuevamente su consentimiento. ACERHO comparte y
          transfiere los datos recopilados en los siguientes supuestos:
        </p>
        <div style="">
          <ul>
            <li>
              Esté prevista en una ley o tratado en los que México sea parte;
            </li>
            <li>
              La transferencia sea efectuada a sociedades subsidiarias o
              afiliadas bajo el control común de cualquiera de ACERHO o a una
              sociedad matriz o a cualquier sociedad del mismo grupo de ACERHO
              que opere bajo los mismos procesos y políticas internas;
            </li>
            <li>
              Sea necesaria en razón de un contrato celebrado o por celebrar en
              interés del cliente;
            </li>
            <li>
              Sea necesaria o legalmente exigida para la salvaguarda de un
              interés público, o para la procuración o administración de
              justicia;
            </li>
            <li>
              Sea precisa para el reconocimiento, ejercicio o defensa de un
              derecho en un proceso judicial; y,
            </li>
            <li>
              Sea precisa para el mantenimiento o cumplimiento de una relación
              jurídica entre el cliente y ACERHO.
            </li>
          </ul>
        </div>
        <br />
        <p style="text-align: justify; ">
          ACERHO cumplirá en todo momento con los principios de protección de
          los datos personales de conformidad con la Ley y su reglamento, por lo
          cual exigirá el apego a la Ley de los terceros a quienes transfiera
          los Datos personales y a adoptar las medidas necesarias para su
          protección.
        </p>
        <p style="text-align: justify; ">
          Los terceros y las entidades receptores de datos personales asumirán
          las mismas obligaciones y/o responsabilidades que el ACERHO ha
          asumido con el cliente.
        </p>
        <h4 style="text-align: left; ">
          V. Derecho de acceso, rectificación, cancelación u oposición.
          (Derechos ARCO)
        </h4>
        <p style="text-align: justify; ">
          Los clientes serán responsables de la exactitud, veracidad,
          autenticidad y vigencia de sus datos, asimismo podrán en cualquier
          momento ejercer sus Derechos ARCO.
        </p>
        <p style="text-align: left; ">
          <strong>ACCESO:</strong> El cliente tiene derecho a acceder a sus
          datos personales que obren en poder de ACERHO, así como conocer el
          presente Aviso.
        </p>
        <p style="text-align: left; ">
          <strong>RECTIFICACIÓN:</strong> El cliente puede solicitar a ACERHO
          la rectificación de sus datos personales en caso de que no estén
          actualizados, sean inexactos o estén incompletos. Para ejercer este
          derecho, deberá entregar a ACERHO la documentación que acredite la
          rectificación solicitada de acuerdo con los datos personales.
        </p>
        <p style="text-align: left; ">
          <strong>CANCELACIÓN:</strong> El cliente puede solicitar el bloqueo y
          la eliminación de datos personales de las bases de datos y registros
          de ACERHO cuando considere que la misma no está siendo utilizada
          adecuadamente o para los fines que dieron origen a la relación
          jurídica, sin embargo ACERHO no estará obligado a cancelar los datos
          personales cuando:
        </p>
        <div style="text-align: justify; ">
          <ul>
            <li>
              a) Se refiera a las partes de un contrato privado, social o
              administrativo y sean necesarios para su desarrollo y
              cumplimiento;
            </li>
            <li>b) Deban ser tratados por disposición legal;</li>
            <li>
              c) Obstaculice actuaciones judiciales o administrativas vinculadas
              a obligaciones fiscales, la investigación y persecución de delitos
              o la actualización de sanciones administrativas;
            </li>
            <li>
              d) Sean necesarios para proteger los intereses jurídicamente
              tutelados del cliente;
            </li>
            <li>
              e) Sean necesarios para realizar una acción en función del interés
              público; y,
            </li>
            <li>
              f) Sean necesarios para cumplir con una obligación legalmente
              adquirida por el cliente.
            </li>
          </ul>
        </div>
        <br />
        <p style="text-align: justify; ">
          Cuando los datos personales hubiesen sido transmitidos con
          anterioridad a la fecha de rectificación o cancelación y sigan siendo
          tratados por terceros, ACERHO informará a los terceros de dicha
          solicitud de rectificación o cancelación, para que proceda a
          efectuarla también.
        </p>
        <p style="text-align: left; ">
          <strong>OPOSICIÓN:</strong> El cliente podrá oponerse al tratamiento
          de sus datos personales.
        </p>
        <p style="text-align: justify; ">
          El ejercicio de cualquiera de los Derechos ARCO no impide el ejercicio
          de algún otro derecho. En caso de que el cliente deseé ejercer alguno
          de sus derechos ARCO, deberá realizar dicha solicitud mediante escrito
          al correo electrónico
          <a
            href="mailto:contacto@acerho.com"
            style="color: rgb(134, 194, 50); "
            >contacto@acerho.com</a
          >
          dicha solicitud deberá contener al menos lo siguiente:
        </p>
        <div style="">
          <ul>
            <li>
              I. El nombre del cliente y domicilio u otro medio para comunicarle
              la respuesta a su solicitud;
            </li>
            <li>
              II. Los documentos que acrediten la identidad o, en su caso, la
              representación legal del cliente;
            </li>
            <li>
              III. La descripción clara y precisa de los datos personales
              respecto de los que se busca ejercer alguno de los derechos antes
              mencionados, y
            </li>
            <li>
              IV. Cualquier otro elemento o documento que facilite la
              localización de los datos personales
            </li>
          </ul>
        </div>
        <br />
        <h4 style="text-align: left; ">
          VI. Datos recabados de Terceros por el Cliente.
        </h4>
        <p style="text-align: justify; ">
          El cliente acepta y reconoce que, en caso de llevar a cabo el
          tratamiento de datos personales de terceros, se encuentra obligado en
          términos de la LFPDPPP a contar con su propio Aviso de Privacidad que
          le permita legalmente recabar, almacenar, usar, transferir, manejar y
          disponer de los datos personales y sensibles de sus propios clientes
          (en lo sucesivo “Terceros”).
        </p>
        <p style="text-align: justify; ">
          Asimismo, el cliente se obliga a establecer en su Aviso de Privacidad
          la autorización de sus clientes, que le permita transferir los datos
          personales y sensibles de éstos en favor de ACERHO con la finalidad
          de que en ACERHO podamos tratar tales datos personales y sensibles
          para el cumplimiento de la prestación de servicios entre ACERHO y el
          cliente.
        </p>
        <p style="text-align: justify; ">
          El cliente será el único responsable de cumplir con las obligaciones
          pactadas en el presente apartado, por lo que en caso de que el cliente
          incumpla y que por éste incumplimiento ACERHO se vea afectado de
          manera directa o indirecta, el cliente se obliga a sacar en paz y a
          salvo a ACERHO, debiendo responder por cualquier daño o perjuicio que
          pudiera surgir en perjuicio de éste.
        </p>
        <p style="text-align: justify; ">
          Los datos personales recabados podrán ser tratados con la finalidad de
          que el cliente pueda atender requerimientos legales de autoridades
          competentes.
        </p>
        <h4 style="text-align: left; ">
          VII. Modificaciones al Aviso de Privacidad.
        </h4>
        <br />
        <p style="text-align: justify; ">
          ACERHO podrá hacer modificaciones al presente Aviso, en estricto
          apego a la Ley y su reglamento, en caso de que se lleven a cabo dichas
          modificaciones, ACERHO pondrá a disposición del cliente la
          información sobre estas, así como el nuevo Aviso de Privacidad
          mediante la plataforma, sitio web de ACERHO o cualquier otro medio
          que ACERHO determine para tal efecto.
        </p>
        <p style="text-align: justify; ">
          De conformidad a lo establecido en el presente numeral, el cliente
          será responsable de mantener su cuenta de correo electrónico,
          domicilio y otro dato de localización para notificaciones actualizados
          y de cerciorarse de que las notificaciones sean recibidas en un medio
          que sea revisado constantemente. En caso de no recibir objeción por
          parte del cliente en un plazo de 10 días posteriores a que el Aviso
          haya sido puesto a su disposición, se entenderá que acepta los cambios
          que pueda sufrir el Aviso.
        </p>
        <h4 style="text-align: left; ">
          VIII. Aceptación.
        </h4>
        <br />
        <p style="text-align: justify; ">
          Al ponerse a disposición el presente Aviso de Privacidad y la no
          manifestación oposición alguna por el cliente, se entenderá que el
          cliente otorga a ACERHO su consentimiento para llevar a cabo el
          tratamiento de los datos personales que hubieran sido proporcionados
          y/o los que con motivo de alguna de las finalidades establecidas en el
          presente proporcione en lo futuro.
        </p>
        <h4 style="text-align: left; ">
          IX. Revocar Consentimiento.
        </h4>
        <br />
        <p style="text-align: justify; ">
          En todo momento, usted podrá revocar el consentimiento que nos ha
          otorgado para el tratamiento de sus datos personales, a fin de que
          dejemos de hacer uso de estos. Para ello, es necesario que presente su
          solicitud en los términos mencionados para el ejercicio de los
          Derechos ARCO.
        </p>
        <p style="text-align: justify; ">
          Si usted considera que su derecho de protección de datos personales ha
          sido lesionado por alguna conducta de nuestros empleados o de nuestras
          actuaciones o respuestas, presume que en el tratamiento de sus datos
          personales existe alguna violación a las disposiciones previstas en la
          Ley Federal de Protección de Datos Personales en Posesión de los
          Particulares, podrá interponer la queja o denuncia correspondiente
          ante el IFAI. Para mayor información visite el sitio web
          <a href="http://www.ifai.org.mx." style="">www.ifai.org.mx.</a>
        </p>
        <h5 style="">
          Fecha última actualización: 01 de diciembre de 2021
        </h5>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false">
          I accept
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false
    };
  }
};
</script>
